import React from 'react';
import { Box, Typography, Button, Snackbar, Alert } from '@mui/material';

const HTTPError = ({ message = "Something went wrong.", errorDetail, onRetry }) => {
    const [open, setOpen] = React.useState(true);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") return;
        setOpen(false);
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            flexDirection="column"
            textAlign="center"
            padding={2}
        >
            <Typography variant="h5" component="h2" gutterBottom>
                {message}
            </Typography>
            <Typography variant="body1">
                {errorDetail || "Please try again or contact support."}
            </Typography>
            {onRetry && (
                <Button variant="contained" color="primary" onClick={onRetry}>
                    Retry
                </Button>
            )}
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {errorDetail || "An unexpected error occurred."}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default HTTPError;