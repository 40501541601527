import React from 'react';
import { Box, Typography } from '@mui/material';

const NoDataFound = ({ message = "No Data Found", subMessage = "Please adjust your filters or try again later" }) => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            flexDirection="column"
            textAlign="center"
            padding={2}
        >
            <Typography variant="h5" component="h2" gutterBottom>
                {message}
            </Typography>
            <Typography variant="body1">
                {subMessage}
            </Typography>
        </Box>
    );
}

export default NoDataFound

